import { InsuranceSepaMandateStep } from "@components/insurance/InsuranceSepaMandateStep"

import { Answers, SurveyConfig } from "../../contexts/survey"
import { InsurancePaywallSurveyStep } from "../insurance/InsurancePaywall"
import { SignUpInsuranceSurveyStep } from "../insurance/InvoiceSignUp"
import DownloadStep from "./DownloadStep"
import PlanReadyStep from "./PlanReadyStep/PlanReadyStep"
import RegistrationStep from "./RegistrationStep"

const surveyConfigExternal = (withYourProgram: boolean): SurveyConfig => {
  return {
    milestones: [0, 2],
    questions: [
      {
        name: "program",
        type: "custom",
        path: "your_program",
        isRequired: true,
        hideHeader: true,
        hideStepper: true,
        hideButtonBar: true,
        hideBackHeader: true,
        component: <PlanReadyStep />,
        shouldRender: (currentAnswers: Answers): boolean => currentAnswers.locale !== "de" || withYourProgram,
        validate: (): boolean => {
          return false
        },
      },
      {
        name: "insurance_paywall",
        type: "custom",
        path: "your_weightloss_plan",
        isRequired: true,
        hideStepper: true,
        hideButtonBar: true,
        hideBackHeader: !withYourProgram,
        component: <InsurancePaywallSurveyStep />,
        shouldRender: (currentAnswers: Answers): boolean =>
          currentAnswers.insurance_checkout === true || !withYourProgram,
        validate: (): boolean => {
          return false
        },
      },
      {
        name: "registration_insurance",
        type: "custom",
        path: "registration_insurance",
        isRequired: true,
        hideStepper: true,
        hideButtonBar: true,
        component: <SignUpInsuranceSurveyStep />,
        shouldRender: (currentAnswers: Answers): boolean =>
          currentAnswers.insurance_checkout === true || !withYourProgram,
        validate: (): boolean => {
          return false
        },
      },
      {
        name: "sepa_mandate",
        type: "custom",
        path: "sepa_mandate",
        isRequired: true,
        hideStepper: true,
        hideButtonBar: true,
        component: <InsuranceSepaMandateStep />,
        shouldRender: (currentAnswers: Answers): boolean =>
          currentAnswers.insurance_checkout === true || !withYourProgram,
      },
      {
        name: "registration",
        type: "custom",
        path: "registration",
        isRequired: true,
        hideStepper: true,
        hideButtonBar: true,
        hideBackHeader: true,
        component: <RegistrationStep />,
        validate: (): boolean => {
          return false
        },
      },
      {
        name: "download",
        type: "custom",
        path: "download",
        isRequired: false,
        hideStepper: true,
        hideButtonBar: true,
        hideBackHeader: true,
        component: <DownloadStep />,
      },
    ],
  }
}

export default surveyConfigExternal
